@import '../App';

.patient-list-container{
   background-color: var(--background-color-faint);
   //min-width: 1040px !important;
   /* height: 100vh !important; */
    min-height: 100vh !important;
   padding: 2% !important;
   padding-bottom: 50px;
   margin-bottom: 0;
   overflow-x: scroll;
}

.page-tittle{
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center !important;
}

.table-header-container{
    background: var(--background-color) !important;
    padding-right: 0 !important;
    border: 0 !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
    /* display: flex; */
    flex-direction: column;
}

.table-header{
    /* background: var(--background-color); */
    height: 62px;
    padding: 20px;
    font-size: 14px;
    /* font-size: calc(6px + 1vmin); */
    font-weight: 600;
    /* display: flex; */
    align-items: center;
    text-align: start;
    /* justify-content: space-around; */
    border-right: 1px solid var(--border-color-faintest);
}
.table-header:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background: var(--background-color);
}

.table-header:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table-header:hover{
    background: var(--background-color-faint-two);
}

.patient-list-row{
    cursor: pointer !important;
    padding: 0 10px;
    border: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background: var(--background-color);
    min-height: 65px !important;
    height: 65px;
}

.tbody-pl:before {
    content:"\200C";
    display:block;
    line-height:30px;
    text-indent:-99999px;
}

.tbody-pl{
    box-shadow: 1px 77px 39px 2px rgba(0,0,0,0.05) !important;
    -webkit-box-shadow: 1px 77px 39px -4px rgba(0,0,0,0.05) !important;
    -moz-box-shadow: 1px 77px 39px -4px rgba(0,0,0,0.05) !important;
    border-radius: 10px;
}

.patient-list-row:nth-child(1) .table-content:first-child{
    border-top-left-radius: 10px !important;
}
.table-content:first-child{
    background: var(--background-color);
}

.patient-list-row:nth-child(1){
    box-shadow: 1px -19px 39px -4px rgba(0,0,0,0.05) !important;
    -webkit-box-shadow: 1px -19px 39px -4px rgba(0,0,0,0.05) !important;
    -moz-box-shadow: 1px -19px 39px -4px rgba(0,0,0,0.05) !important;
}

.patient-list-row:nth-child(1) .table-content:last-child{
    border-top-right-radius: 10px !important;
}

.spacer{
    height: 20px !important;
}

.patient-list-row:hover{
    background: var(--background-color-faint);
    .table-content:first-child{
        background: var(--background-color-faint-two);
    }
}

.wave-reports-body:hover{
    border: 1px solid var(--border-color-fainter);
}

.wave-reports-body{
    background: var(--lightest-gray);
    border: 1px solid var(--lightest-gray);
}

.patient-photo-list:hover .list-checkbox{
    visibility: visible !important;
}

.patient-photo2{
    transition: 0.3s;
    max-height: 38px;
    opacity: 1;
    transition: max-width 0.15s ease-in-out, all 0.15s ease-in-out;
    transition-delay: max-width 0.15s;
}

.patient-photo{
    transition: 0.3s;
    max-height: 38px;
    opacity: 1;
    transition: max-width 0.15s ease-in-out, all 0.15s ease-in-out;
    transition-delay: max-width 0.15s;
}
.patient-photo-list{
    display: flex;
    align-items: center;
}

.patient-photo-list:hover .patient-photo{
    min-width: 0 !important;
    max-width: 0 !important;
    box-shadow: none !important;
    /* max-height: 0 !important; */
    transform: scale(0);
    transition: max-width 0.15s ease-in-out, all 0.15s ease-in-out;
    transition-delay: max-width 0.15s;
}

.patient-photo-list:hover .patient-status{
    min-width: 0 !important;
    max-width: 0 !important;
    transition: max-width 0.15s ease-out;
}

.status-tooltip-div .status-tooltip-block{
    visibility: hidden;
    position: absolute;
    z-index: 1;
    top:30px;
    left:-20px;
    border: 1px solid var(--border-color-faintest);
    box-sizing: border-box;
    border-radius: 4px;
    background: var(--background-color);
}

.status-tooltip-svg{
    width: 10px !important;
    height: 10px !important;
    margin-left: 5px !important;
    align-self: center !important;
}

.status-tooltip-div{
    position: relative;
    display: inline-block;
}

.status-tooltip{
    fill: var(--border-color-fainter);
}

.status-tooltip-div:hover .status-tooltip {
    fill: var(--bs-primary) !important;
}

.status-tooltip-div:hover .status-tooltip-block{
    visibility: visible;
}

.header-sort-symbol{
    margin-left: 0;
    padding-left: 5px;
    text-align: center;
    color: #afafaf;
    cursor: pointer;
}

.table-content{
    padding: 10px !important;
    font-size: 14px;
    /* border-left: 1px solid var(--border-color-faintest); */
    border-bottom: 1px solid var(--border-color-faintest);
    flex-direction: row;
    align-items: center;
    word-wrap: break-word;
    word-break: break-all;
}

.hyphenate {
    word-wrap: break-word;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

.table-content-subtext{
    color: var(--text-color-faint);
}

.dot-menu{
    cursor: pointer;
    width: 44px !important;
    text-align: center;
    display: flex;
    align-items: center;
    padding-right: 30px !important;
    padding-left: 20px !important;
    justify-content: space-around;
    border-top-right-radius: 10px;
    background-color: var(--background-color);
}

.dot-menu:hover{
    background-color: var(--border-color-fainter);
}


.buttons-round{
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 100px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
    padding: 8px;
    line-height: 22px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 10px;
    margin-left: 10px;
    z-index: 110;
    border: 0 !important;
    width: 44px;
    height: 44px;
    text-align: center;
}

.white-buttons{
    @include white-buttons;
    background: var(--background-color) !important;
    min-width: max-content;
    z-index: 110;
    border: 0 !important;
}

.white-buttons-round{
    background: var(--background-color) !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
    padding: 8px;
    line-height: 22px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 10px;
    margin-left: 10px;
    z-index: 110;
    border: 0 !important;
    width: 44px;
    height: 44px;
    text-align: center;
}

.blue-buttons{
    background: var(--bs-primary) !important;
    color: var(--background-color) !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 100px !important;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    min-width: 132px !important;
    padding: 6px 27px !important;
    line-height: 22px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 10px;
    margin-left: 10px;
    border: 0;
}

.cross-button{
    background: var(--background-color) !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 50% !important;
    height: 42px;
    width: 42px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: 0;
}
.list-checkbox2:checked{
    border: none !important;
}
.list-checkbox:checked{
    border: none !important;
}

.list-checkbox-filter:checked{
    border: none !important;
}

.list-checkbox-filter{
    height: 22px !important;
    width: 22px !important;
    color: var(--bs-primary) !important;
    border: 2px solid var(--border-color-fainter) !important;
    box-shadow: none !important;
    margin-right: 15px !important;
    border-radius: 11px !important;        
    align-self: center;
    justify-self: center;
    margin-top: 0 !important;
}

.filter-list-container:first-child{
    margin-top: 0 !important;
}
.filter-list-container:last-child{
    margin-bottom: 0 !important;
}

.list-checkbox2{
    height: 22.8px !important;
    width: 22.8px !important;
    color: var(--bs-primary) !important;
    border: 2px solid var(--border-color-fainter) !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    position: relative;
    left: 7px;
    align-self: center;
    justify-self: center;
}

.list-checkbox{
    height: 22.8px !important;
    width: 22.8px !important;
    color: var(--bs-primary) !important;
    border: 2px solid var(--border-color-fainter) !important;
    box-shadow: none !important;
    border-radius: 50% !important;
    position: relative;
    left: 7px;
    align-self: center;
    justify-self: center;
}

.cross-button:hover, .white-buttons:hover{
    background: var(--border-color-fainter) !important;
    border: 0;
}

.blue-buttons:hover{
    background: rgb(71, 103, 182) !important;
}

.mrn-container:hover .mrn-copy-img{
    visibility: visible;
    display: block;
}
.mrn-copy-img{
    visibility: hidden;
    display: none;
    right: 0;
    height: 0;
}

.triangle-with-shadow {
    width: 42px;
    height: 42px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0px 2px -3px rgba(0, 0, 0, 0.15);
  }
.triangle-with-shadow:after {
    content: "";
    position: absolute;
    width: 21px;
    height: 21px;
    background: var(--background-color);
    transform: rotate(45deg);
    top: 33px;
    left: 15px;
    box-shadow: -1px -1px 2px -1px rgba(0, 0, 0, 0.15);
}

.group-name:first-child{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.group-name:last-child{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.result-page-limit{
    text-align: center;
    border-radius: 2px;
    height: 26px;
    width: 33px;
    box-sizing: border-box;
    border: 1px solid var(--border-color-faintest);
    cursor: pointer;
}

.result-page-limit:hover{
    border: 1px solid var(--text-color-faint);
}

.risk-tool-tip-pl{
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
}

.risk-tooltip-icon-pl:hover .risk-tool-tip-pl{
    display: block;
}

.Toastify__toast-body {
    padding-left: 20px !important;
    padding-right: 20px !important;
    height: 44px;
    max-width: max-content;
    vertical-align: middle;
    background: var(--bs-primary-dark);
    color: var(--background-color);
    position: absolute;
    right: 40px;
}

.Toastify__toast {
    background: transparent !important;
    box-shadow: none !important;
}

.physician-info-pl{
    display: none;
}

.table-content:hover .physician-info-pl{
    display: flex;
}

.search-patients-name-list:hover{
    background: var(--background-color-faint);
}
search-patients-name-list:last-child{
    border-radius: 10px;
}

.rx-group-tool-tip{
    background: var(--background-color);
    padding: 10px 20px;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: none;
    position: absolute;
    top: 10px;
    left: 10px;
}
.rx-group-text:hover .rx-group-tool-tip{
    display: block;
}


.tx-status-text:hover .tx-status-tool-tip{
    display: inline-flex !important;
}

.tx-status-tool-tip{
    background: var(--background-color);
    padding: 10px 20px;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    display: none;
    position: absolute;
    width: max-content;
    width: -moz-max-content;
    max-width: 250px;
    word-break: break-all;
    top: 10px;
    left: 10px;
}
