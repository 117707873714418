.lng-img{
    width: 14px;
    height: 14px;
    align-self: center;
}

.lng-img-selected{
    width: 18px;
    height: 18px;
    align-self: center;
    flex: 1;
}

