.section_title{
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
}

.sub_title{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}

.as_status{
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

.individual_tables{
    margin: 0 10px;
    padding: 0 !important;
}
.individual_tables:first-child{
    margin-left: 0;
}
.individual_tables:last-child{
    margin-right: 0;
}

.table_input_text{
    padding: 5px 9px;
    background: transparent;
    border: 0;
    outline: 0;
    color: var(--text-color);
}
.table_input_text::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: var(--wave-blue);
    opacity: 1;
    font-weight: 600;
}
.table_input_text::-moz-placeholder { /* Firefox 19+ */
    color: var(--wave-blue);
    opacity: 1;
    font-weight: 600;
}
.table_input_text:-ms-input-placeholder { /* IE 10+ */
    color: var(--wave-blue);
    opacity: 1;
    font-weight: 600;
}
.table_input_text:-moz-placeholder { /* Firefox 18- */
    color: var(--wave-blue);
    opacity: 1;
    font-weight: 600;
}

.row_header{
    padding: 5px 9px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    background: var(--wave-purple-faint);
}

.react-datetime-picker__wrapper{
    border: 0 !important;
}

.react-datetime-picker__inputGroup__input{
    color: var(--wave-blue) !important;
    font-weight: 600 !important;
    outline: 0 !important;
}

@media print {
    .pagebreak {
        color: white;
        clear: both;
        page-break-before: always;
    } /* page-break-after works, as well */
}

.tooltip-container-as:hover .tooltip-as {
    display: block;
}


.tooltip-container-as:hover{
    display: flex;
}
.tooltip-as{
    display: none;
    position: relative;
    left: -30px;
    top: 10px;
    background: var(--background-color);
    border: 0;
    border-radius: 10px;
    padding: 5px 10px;
    color: var(--text-color);
    width: 100%;
    min-width: 150px;
    font-size: 12px;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
}

.summary-table-cell-header{
    height:30px;
    margin:0;
    color: var(--text-color) !important;
    padding: 5px !important;
    font-weight: 600 !important;
    background-color: var(--background-color-faint) !important;
    border: 1px solid var(--border-color) !important;
}

.summary-table-cell{
    height:30px;
    margin:0;
    color: var(--text-color) !important;
    padding: 5px !important;
    font-weight: 400;
    border: 1px solid var(--border-color) !important;
}

.summary-section-header{
    color: var(--text-color) !important;
    padding:0 !important;
    margin:0 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
}

.summary-section-sub-header{
    color: var(--text-color) !important;
    padding:0 !important;
    margin:0 !important;
    font-weight: 600 !important;
    font-size: 13px !important;
}

.summary-table-cell-decision{
    height:100px !important;
    margin:0;
    color: var(--text-color) !important;
    padding: 5px !important;
    font-weight: 400;
    border: 1px solid var(--border-color) !important;
}