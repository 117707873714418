
.top-search-input-focused{
    padding: 10px 15px !important;
    width: 364px !important;
    transition: width 0.3s ease-in-out;
    border: 0 !important;
    max-width: unset;
    height: 44px;
    background: transparent !important;
    outline: none;
}

.top-search-input:hover{
    border: 0;
}

.top-search-input{
    border: 0 !important;
    margin-left: 10px !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
    max-width: 0 !important;
    max-height: 0 !important;
    transition: width 0.5s ease-in-out;
    position: absolute;
}

.search-patients-name-list{
    border-bottom: 1px solid var(--border-color-faintest);
}
.search-patients-name-list:last-child{
    border-bottom: 0;
}