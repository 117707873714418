body {
  margin: 0;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --wave-blue: #4676F4;
  --wave-blue-rgb: 70, 118, 244;
  --dark-blue: #2D4175;
  --wave-clay: #DE6D5E;
  --wave-clay-rgb: 222, 109, 94;
  --wave-purple: #6064DE;
  --wave-purple-rgb: 96, 100, 222;
  --wave-purple-faint: rgba(96, 100, 222, 0.2);

  --darkest-gray: #191919;
  --dark-gray: #6F6F6F;
  --medium-gray: #BDBDBD;
  --medium-light-gray: #CDCDCD;
  --light-gray: #F3F3F3;
  --lightest-gray: #F9F9F9;

  --stone-light: #CCCFCF;
  --wave-green: #71BD24;
  --wave-green-rgb: 113, 189, 36;
  --wave-orange-rgb: 253, 145, 15;
  --light-yellow: #F7E5A4;
  --light-orange: #FED39F;
  --wave-yellow-rgb: 238, 203, 73;
  --overlay-purple: rgba(96, 100, 222, 0.13);

  --unbearable-symptom: #CC0000;
  --severe-symptom: #F1421B;
  --moderate-symptom: #FD910F;
  --mild-symptom: #EECB49;
  --none-symptom: #F3F3F3;

  --border-color: var(--darkest-gray);
  --border-color-two: var(--dark-gray);
  --border-color-faint: var(--medium-gray);
  --border-color-fainter: var(--medium-light-gray);
  --border-color-faintest: var(--light-gray);
  --text-color: var(--darkest-gray);
  --text-color-faint: var(--dark-gray);
  --text-color-fainter: var(--medium-gray);
  --text-color-faintest: var(--medium-light-gray);
  --background-color: #FFFFFF;
  --background-color-faint: var(--light-gray);
  --background-color-faint-two: #f3f3f3;
  --background-color-faintest: var(--lightest-gray);
  --bs-primary: var(--wave-blue);
  --bs-primary-dark: var(--dark-blue);
}

[data-theme='dark'] {
  --border-color: var(--light-gray);
  --border-color-two: var(--medium-light-gray);
  --border-color-faint: var(--medium-gray);
  --border-color-fainter: var(--dark-gray);
  --border-color-faintest: var(--darkest-gray);
  --text-color: #FFFFFF;
  --text-color-faint: var(--lightest-gray);
  --text-color-fainter: var(--light-gray);
  --text-color-faintest: var(--medium-light-gray);
  --background-color: #000000;
  --background-color-faint: #262626;
  --background-color-faint-two: var(--dark-gray);
  --background-color-faintest: var(--dark-gray);
  --bs-primary: var(--wave-blue);
  --bs-primary-dark: #a5bbf8;
}

[data-theme='light'] {
  --border-color: var(--darkest-gray);
  --border-color-two: var(--dark-gray);
  --border-color-faint: var(--medium-gray);
  --border-color-fainter: var(--medium-light-gray);
  --border-color-faintest: var(--light-gray);
  --text-color: var(--darkest-gray);
  --text-color-faint: var(--dark-gray);
  --text-color-fainter: var(--medium-gray);
  --text-color-faintest: var(--medium-light-gray);
  --background-color: #FFFFFF;
  --background-color-faint: var(--light-gray);
  --background-color-faint-two: #f3f3f3;
  --background-color-faintest: var(--lightest-gray);
  --bs-primary: var(--wave-blue);
  --bs-primary-dark: var(--dark-blue);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.plain-a{
  text-decoration: none !important;
}
.plain-a:hover{
  text-decoration: none !important;
}

@font-face {
  font-family: 'Work Sans';
  src: local('Work Sans'), url(./assets/fonts/Work_Sans/WorkSans-VariableFont_wght.ttf) format('truetype');
}