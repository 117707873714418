.side-nav-container{
    max-width: 80px;
    min-width: 80px;
    height: 100vh;
    position: fixed !important;
    left: 0;
    top: 0;
    /* pin to left/start side, always on top */
    background: url('../assets/backgrounds/SideBar.png');
    background-color: var(--bs-primary);
    object-fit: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1000;
    background-color: var(--bs-primary);
    flex-direction: column;
    text-align: center;
    cursor: default;
}

.side-menu-icon{
    background: rgba(255, 255, 255, 0.2);
    border-radius: 10px;
    width: 50px;
    height: 50px;
}

.icon-selected{
    background: var(--background-color);
}

.name-sidenavbar{
    position: absolute; 
    bottom: 23px !important;
    left: 17px; 
    color: var(--background-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 36px;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: space-around;
    text-transform: uppercase;
    object-fit: cover !important;
}

.user-profile-icon-nb:hover{
    background: var(--bs-primary-dark) !important;
}

.logout-icon:hover{
    background: var(--bs-primary-dark);
}


.nav-icon-container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.notifications-nav-container:hover .notifications-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.staff-accounts-nav-container:hover .staff-accounts-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.activity-log-nav-container:hover .activity-log-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.patient-metrics-nav-container:hover .patient-metrics-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.risk-nav-container:hover .risk-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.tasks-nav-container:hover .tasks-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.patient-list-nav-container:hover .patient-list-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.surveys-nav-container:hover .surveys-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.logout-icon:hover .name-nav-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
}
.user-profile-icon-nb:hover .user-profile-icon-nb-tooltip{
    visibility: visible !important;
    display: block !important;
    opacity: 1;
    transition: opacity  0s linear 1s;
    bottom: 60px;
}

.nav-icon-tooltip{
    opacity: 0;
    width: max-content;
    position: absolute !important;
    visibility: hidden;
    display: none;
    left: 100%;
    bottom: 5px;
    color: var(--background-color);
    line-height: 16px;
    font-size: 14px;
    font-weight: 600;
    background: var(--bs-primary-dark);
    padding: 8px 10px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.name-nav-tooltip{
    opacity: 0;
    width: max-content;
    position: absolute !important;
    visibility: hidden;
    display: none;
    left: 60px !important;
    bottom: 5px;
    color: var(--background-color);
    line-height: 16px;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 600;
    background: var(--bs-primary-dark);
    padding: 8px 10px;
    box-shadow: 0px 10px 25px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.nav-icon-tooltip:before{
    content:'';
    display:block;
    width:0;
    height:0;
    position:absolute;
    border-top: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right:6px solid var(--bs-primary-dark);
    left:-6px;
    top:30%;
}