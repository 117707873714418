.staff-accounts-container{
    background-color: var(--background-color-faint);
    min-width: 1440px !important;
    /* height: 100vh !important; */
    padding: 2% !important;
    margin-left: 30px !important;
    margin-right: 30px !important;
    padding-bottom: 50px;
    margin-bottom: 0;
    overflow: visible;
}

.sa-page-tittle{
    font-style: normal;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    align-items: center !important;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
    text-align: left;
    color: var(--text-color);
}


.top-search-input-focused{
    padding: 10px 15px !important;
    width: 364px !important;
    transition: width 0.3s ease-in-out;
    border: 0 !important;
    max-width: auto;
    height: 44px;
    background: transparent !important;
    outline: none;
}

.top-search-input:hover{
    border: 0;
}

.top-search-input{
    border: 0 !important;
    margin-left: 10px !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
    max-width: 0 !important;
    max-height: 0 !important;
    transition: width 0.5s ease-in-out;
    position: absolute;
}

.table-header-container{
    background: var(--background-color) !important;
    padding-right: 0 !important;
    border: 0 !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
    /* display: flex; */
    flex-direction: column;
}

.table-header{
    /* background: var(--background-color); */
    height: 62px;
    padding: 20px;
    font-size: 14px;
    /* font-size: calc(6px + 1vmin); */
    font-weight: 600;
    /* display: flex; */
    align-items: center;
    text-align: start;
    /* justify-content: space-around; */
    border-right: 1px solid var(--border-color-faintest);
}
.table-header:first-child{
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.table-header:last-child{
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}

.table-header:hover{
    background: var(--background-color-faint-two);
}

.staff-accounts-row{
    cursor: default;
    padding: 0 10px;
    border: 0;
    margin-left: 0 !important;
    margin-right: 0 !important;
    background: var(--background-color);
    min-height: 65px !important;
    height: 65px;
}

.tbody-sa:before {
    content:"\200C";
    display:block;
    line-height:30px;
    text-indent:-99999px;
}

.tbody-sa{
    box-shadow: 1px 77px 39px 2px rgba(0,0,0,0.05) !important;
    -webkit-box-shadow: 1px 77px 39px -4px rgba(0,0,0,0.05) !important;
    -moz-box-shadow: 1px 77px 39px -4px rgba(0,0,0,0.05) !important;
    border-radius: 10px;
}

.patient-photo2{
    transition: 0.3s;
    max-height: 38px;
    opacity: 1;
    transition: max-width 0.15s ease-in-out, all 0.15s ease-in-out;
    transition-delay: max-width 0.15s;
}

.spacer{
    height: 20px !important;
}

.staff-accounts-row:nth-child(1) .table-content:first-child{
    border-top-left-radius: 10px !important;
}

.staff-accounts-row:nth-child(1){
    box-shadow: 1px -19px 39px -4px rgba(0,0,0,0.05) !important;
    -webkit-box-shadow: 1px -19px 39px -4px rgba(0,0,0,0.05) !important;
    -moz-box-shadow: 1px -19px 39px -4px rgba(0,0,0,0.05) !important;
}

.staff-accounts-row:nth-child(1) .table-content:last-child{
    border-top-right-radius: 10px !important;
}