.react-pdf__Page__canvas{
    border-radius: 10px !important;
    margin-bottom: 20px !important;
}
.react-pdf__Page:last-child .react-pdf__Page__canvas{
    margin-bottom: 0 !important;
}


.white-buttons-wr{
    min-width: max-content;
    background: var(--background-color) !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 100px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
    padding: 6px 27px !important;
    line-height: 22px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 10px;
    margin-left: 10px;
    z-index: 110;
    border: 0 !important;
}


.white-buttons-round-wr{
    background: var(--background-color) !important;
    box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
    border-radius: 100px !important;
    display: flex !important;
    flex-direction: row;
    justify-content: center !important;
    justify-items: center !important;
    align-items: center !important;
    padding: 8px;
    line-height: 22px !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    margin-right: 10px;
    margin-left: 10px;
    z-index: 110;
    border: 0 !important;
    width: 44px;
    height: 44px;
    text-align: center;
}


.page-list-titles{
    background: var(--background-color);
    border-bottom: 1px solid var(--border-color-faintest);
}
.page-list-titles-selected{
    background: var(--background-color-faint);
}
.page-list-titles-selected:hover{
    background: var(--background-color-faint) !important;
}
.page-list-titles:first-child{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.page-list-titles:last-child{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.page-list-titles:hover{
    background: var(--lightest-gray);
}