.App {
  background-color: var(--background-color-faint);
  width: auto;
  height: 100% !important;
  min-height: 100vh !important;
  display: block;
  margin:0;
  margin-left: 80px;
  font-family: 'Work Sans', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: var(--background-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--text-color);
}

.circular-border{
  border: 2px solid var(--background-color);
  border-radius: 50%;
  padding: 20px !important;
  border-color: var(--background-color);
  -moz-border-radius:50%;
  -webkit-border-radius:50%;
  text-align: center;
}

.reset-button{
  background: var(--background-color) !important;
  color: var(--text-color-faint) !important;
}
.reset-button:hover{
  background: var(--bs-primary) !important;
  color: var(--background-color) !important;
}

@mixin white-buttons{
  background: var(--background-color);
  fill: var(--text-color);
  color: var(--text-color);
  box-shadow: 0px 3px 15px 2px rgba(0, 0, 0, 0.05) !important;
  border-radius: 100px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 6px 27px;
  line-height: 22px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin-right: 10px;
  margin-left: 10px;
}

.person-card-container{
  white-space: nowrap;
}

.person-card-container:hover .person-card-container-tool-tip{
  display: inline-flex !important;
}

.person-card-container-tool-tip{
  display: none;
  position: absolute;
  width: max-content;
  width: -moz-max-content;
  top: 10px;
  left: 10px;
}