.login-container{
    width: calc(100% + 70px) !important;
    max-width: calc(100% + 70px) !important;
    position: absolute !important;
    left:-70px;
    margin:0 !important;
    min-width: 1024px !important;
    height: 100vh !important;
    padding: 0 !important;
    background: var(--background-color);
}

.input-field{
    background: var(--background-color);
    border: 1px solid var(--border-color-fainter);
    box-shadow: none !important;
    outline: 0px !important;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 40px 15px 20px;
    min-width: max(25vw, 375px);
    height: 52px;
}

.password-checks{
  max-height: 100px;
  transition: max-height 0.25s ease-out;
}
.password-checks-hidden{
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.15s ease-out;
}

.incorrect-input-field{
    background: #FFF2F2;
    box-shadow: none !important;
    outline: 1px  !important;
    border: 1px solid #DE6D5E;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px 40px 15px 20px;
    min-width: max(25vw, 375px);
    height: 52px;
}

  .path-animate {
    cursor: default;
    stroke: var(--background-color);
    stroke-dasharray: 480;
    animation: draw 7s ease-in-out forwards, fill-white 7s ease-in-out forwards;
    animation-iteration-count: 2;
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: 480;
    }
    60% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes fill-white  {
    0% {
      fill: var(--background-color);
    }
    60% {
        fill: var(--background-color);
    }
    4% {
        fill: rgba(255, 255, 255, 0.123);
    }
    55% {
        fill: #94adeba8;
    }
    100% {
      fill: var(--background-color);
    }
  }